<template>
  <div class="error-404">
    <v-app-bar app height="56" class="tw-shadow" v-if="currentUser">
      <v-spacer />
      <HeaderAccount :loading="false" :current-user="currentUser" />
    </v-app-bar>
    <screen-error :label="$t('error.button.back')" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScreenError from '@/components/Screen/Error.vue'
import HeaderAccount from '@/components/Header/Account.vue'

export default {
  name: 'Error404',
  components: {
    ScreenError,
    HeaderAccount,
  },
  computed: {
    ...mapState({
      currentUser: state => state.backoffice.currentUser,
    }),
  },
}
</script>

<style lang="scss" scoped>
.error-404 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
